import Home from './Pages/Home/Home';
import './partials/_common.scss';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
