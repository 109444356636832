import { firestore } from '../../firebase.js';
import './Contact.scss';

function Contact() {

  const submitHandler = (e) => {
    e.preventDefault();
    firestore.collection('mail').doc().set({
      to: [`jinhaeng.lee.87@gmail.com`],
      message: {
        html: `
          <h3>Name: ${e.target.name.value}</h3>
          <h3>Email: ${e.target.email.value}</h3>
          <h3>Message:</h3>
          <p>${e.target.message.value}</p>
        `,
        subject: 'IMPORTANT: Contact through Portfolio',
        text: `
          Name: ${e.target.name.value}
          Email: ${e.target.email.value}
          Message:
          ${e.target.message.value}
        `,
      }
    })
      .then(res => {
        console.log('success!');
        e.target.reset();
        window.alert('Submitted!');
      })
      .catch(err => {
        console.log(err);
        window.alert(err);
      })
  }

  return (
    <section className='contact' id='contact'>
    	<h2 className='contact__title'>Contact Me</h2>
    	<div className='contact__contentbox'>
    		<div className='contact__infobox'>
    			<h4 className='contact__subtitle'>Contact Info</h4>
    			<div className='contact__info'>
    				<div class = 'icon'>
							<i class="fa fa-map-marker"></i>
						</div>
    				<div className='contact__details'>
    					<h3 className='contact__infotitle'>Address</h3>
    					<p className='contact__address'>Walnut Creek, California,</p>
    					<p className='contact__address'>94597</p>
    				</div>
    			</div>
    			<div className='contact__info'>
    				<div class = 'icon'>
							<i class='fa fa-phone'></i>
						</div>
    				<div className='contact__details'>
    					<h3 className='contact__infotitle'>Phone</h3>
    					<p className='contact__address'>832-466-1356</p>
    				</div>
    			</div>
    			<div className='contact__info'>
    				<div class = 'icon'>
							<i class='fa fa-envelope-o'></i>
						</div>
    				<div className='contact__details'>
    					<h3 className='contact__infotitle'>Email</h3>
    					<p className='contact__address'>jinhaeng.lee.87@gmail.com</p>
    				</div>
    			</div>
    		</div>
    		<form className='contact__form' onSubmit={(e) => submitHandler(e)}>
    			<h4 className='contact__subtitle'>Message Me</h4>
    			<input
            className='contact__input'
            type="text"
            placeholder='Full Name'
            name='name'
            required={true}
          />
    			<input
            className='contact__input'
            type="email"
            placeholder='Email'
            name='email'
            required={true}
          />
    			<textarea
            className='contact__textarea'
            name="message"
            placeholder='Your Message'
            required={true}
          ></textarea>
    			<button className='contact__button' type='submit'>SEND</button>
    		</form>
    	</div>
    </section>
  );
}

export default Contact;
