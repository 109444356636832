import Header from '../../Components/Header/Header';
import Hero from '../../Components/Hero/Hero';
import About from '../../Components/About/About';
import Education from '../../Components/Education/Education';
import Project from '../../Components/Project/Project';
import Skills from '../../Components/Skills/Skills';
import Contact from '../../Components/Contact/Contact';
import './Home.scss';

function Home() {
  return (
    <>
    	<Header />
    	<Hero />
    	<About />
        <Skills />
    	<Education />
    	<Project />
    	<Contact />
    </>
  );
}

export default Home;
