import './About.scss';

function About() {
  return (
    <section className='about' id='about'>
      <div className='about__box'>
        <h2 className='about__title'>About Me</h2>
        <div className='about__contentbox'>
          <div className='about__textbox'>
            <h3 className='about__subtitle'>I'm an Analyst/Scientist.</h3>
            <p className='about__text'>
              I'm a data driven analyst/scientist with my background in both Geophysics and Data Analytics. Having Master’s degree in both, I have strong foundation on Signal Processing, Data Wrangling, Data Analysis, Data Visualization, Machine Learning and Automation. I seek to be inspired, proactively learn and adopt to be the mastermind of data.
            </p>
          </div>
          <img className='about__image' src="https://drive.google.com/thumbnail?id=19orv5O0bml_J4Bslt84llRPRg4-U2MzE" alt="" />
        </div>
      </div>
    </section>
  );
}

export default About;
