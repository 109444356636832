import './Hero.scss';

function Hero() {
  return (
    <section className='hero' id='home'>
    	<h2 className='hero__titlem'>Hello, I'm</h2>
    	<h1 className='hero__titlel'>John (Jin Haeng) Lee</h1>
    	<h2 className='hero__titles'>I’m an Analyst/Scientist and this page serves a purpose of providing a brief summary of me and the projects that I have done.</h2>
    	<a className='hero__button' href="#about">ABOUT ME</a>
    </section>
  );
}

export default Hero;