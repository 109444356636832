import './Skills.scss';

function Skills() {
  return (
    <section className='skills' id='skills'>
      <h2 className='skills__title'>Skills</h2>
      <ul className='skills__list'>
        <li className='skillbox'>
          <p className='skillbox__skill'>Data Wrangling (5/5</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Automation 5/5</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Software Quality Assurance 5/5</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Python (5/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Machine Learning (5/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Data Visualization (5/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Data Analysis (5/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Statistics/Mathematics (5/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--5'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>R (4/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--4'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>Matlab (4/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--4'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>SQL (4/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--4'></div>
          </div>
        </li>
        <li className='skillbox'>
          <p className='skillbox__skill'>PyTorch (3/5)</p>
          <div className='skillbox__outer'>
            <div className='skillbox__inner skillbox__inner--3'></div>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default Skills;
