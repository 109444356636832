import { slide as Menu } from 'react-burger-menu'
import './Header.scss';

function Header() {
  return (
    <header className='header'>
    	<a className='header__mainlink' href="">PORTFOLIO</a>
      <Menu left>
        <a className='header__link' href="#home">Home</a>
        <a className='header__link' href="#about">About</a>
        <a className='header__link' href="#skills">Skills</a>
        <a className='header__link' href="#education">Education</a>
        <a className='header__link' href="#project">Project</a>
        <a className='header__link' href="#contact">Contact</a>
      </Menu>
    	<nav className='header__nav'>
    		<a className='header__link' href="#home">Home</a>
    		<a className='header__link' href="#about">About</a>
        <a className='header__link' href="#skills">Skills</a>
    		<a className='header__link' href="#education">Education</a>
    		<a className='header__link' href="#project">Project</a>
    		<a className='header__link' href="#contact">Contact</a>
    	</nav>
    </header>
  );
}

export default Header;
