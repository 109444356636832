import './Project.scss';
import leagueImage from '../../assets/images/league-of-legends.jpg';

function Project() {
  return (
    <section className='project' id='project'>
    	<h2 className='project__title'>Project</h2>
    	<h3 className='project__subtitle'>Relevant Projects</h3>
    	<ul className='project__list'>
    		<li className='projectcard'>
    			<img className='projectcard__img' src="https://drive.google.com/thumbnail?id=1q6-5rrNnLU5bLIQHZ_lL1THBDwD08swS" alt="" />
    			<div className='projectcard__textbox'>
    				<h3 className='projectcard__title'>Colorado Hydrocarbon Production & Socio-economics</h3>
	    			<p className='projectcard__description'>
	    				The oilonomics (Oil + socio-economics) is a web application for analyzing impact of hydrocarbon production on socio-economics with Colorado as the study area. Extensive analyses were done through regression, correlation and forecasted how each socio-economic factors will behave accordingly to hydrocarbon production.
	    			</p>
            <div className='projectcard__linkbox'>
              <a
                className='projectcard__link'
                href="http://www.theoilonomics.com/"
                target="_blank"
              >
                Web App
              </a>
            </div>
    			</div>
    		</li>
    		<li className='projectcard'>
    			<img className='projectcard__img' src="https://drive.google.com/thumbnail?id=1FTxzNTBvUkCf_ExtiL2OGpdT-ViLRCbQ" alt="" />
    			<div className='projectcard__textbox'>
    				<h3 className='projectcard__title'>Deep Learning for CheXpert</h3>
	    			<p className='projectcard__description'>
	    				With utilization of DenseNet121 and PyTorch, a new and existing machine learning models are evaluated and created. Given chest diseases (labels) as predictors, numerous features from clinical data, in conjunction with X-ray images, the model is evaluated with over 85% AUC.
	    			</p>
            <div className='projectcard__linkbox'>
              <a
                className='projectcard__link'
                href="https://github.com/jinhaeng87/CheXpert-Deep-Learning/tree/main"
                target="_blank"
              >
                Git Repo
              </a>
              <a
                className='projectcard__link'
                href="https://youtu.be/ZMcT7-Xf5c0"
                target="_blank"
              >
                Presentation
              </a>
            </div>
    			</div>
    		</li>
    		<li className='projectcard'>
    			<img className='projectcard__img' src="https://drive.google.com/thumbnail?id=1bThR_Q4-GVSJwJczfdoO19lEifM4Jofb" alt="" />
    			<div className='projectcard__textbox'>
    				<h3 className='projectcard__title'>Predicting Flight Delays</h3>
	    			<p className='projectcard__description'>
	    				Three multiclass-multiootput probabilistic models (Random Forest, XGBoosting, KNN) are discussed and evaluated with given METAR-ASPM data provided for major airports in the United States. The outputs are generated as an average of an airport’s probability of a flight to be delayed. Extensive parameter tunings are involved via grid-searching to optimize models and increase accuracy.
	    			</p>
            <div className='projectcard__linkbox'>
              <a
                className='projectcard__link'
                href="https://github.com/jinhaeng87/predict_flight_delay"
                target="_blank"
              >
                Git Repo
              </a>
              <a
                className='projectcard__link'
                href="https://drive.google.com/file/d/1i1CXnfMe4b7hzKwHsR5VmTl8ORD_zqtS/view"
                target="_blank"
              >
                Report
              </a>
            </div>
    			</div>
    		</li>
    		<li className='projectcard'>
    			<img className='projectcard__img' src="https://drive.google.com/thumbnail?id=1ewZymNoH1uOreCZrrcFfNWQzm4oszUoY" alt="" />
    			<div className='projectcard__textbox'>
    				<h3 className='projectcard__title'>Raven's Progressive Matrices Solver</h3>
	    			<p className='projectcard__description'>
	    				Raven’s Progressive Matrices (RPM) solver is an Artificial Intelligent Agent which attempts to solve for 2 x 2 and 3 x 3 RPM  problems. Utilizing multiple image processing techniques and transformations via Python’s Pillow and OpenCV, the agent is designed to optimize its workflow in search for best matching choice among 6 (2 x 2) or 8 (3 x 3) choices. The accuracy of the performance lies at around 80% with efficiency at 10 seconds for 20 questions.
	    			</p>
            <div className='projectcard__linkbox'>
              <a
                className='projectcard__link'
                href="https://github.com/jinhaeng87/RPM-project"
                target="_blank"
              >
                Git Repo
              </a>
            </div>
    			</div>
    		</li>
        <li className='projectcard'>
          <img className='projectcard__img' src={leagueImage} alt="" />
          <div className='projectcard__textbox'>
            <h3 className='projectcard__title'>Predicting Win/Loss for League of Legend</h3>
            <p className='projectcard__description'>
              Utilizing supervised machine learning algorithms, the model predicts odds of winning the infamous online game based on the in game stats that are readily available online. Extensive EDA, feature engineering and feature selection were performed to accurately retrieve the highest performing classification models.
            </p>
            <div className='projectcard__linkbox'>
              <a
                className='projectcard__link'
                href="https://github.com/jinhaeng87/Predicting_LoL"
                target="_blank"
              >
                Git Repo
              </a>
            </div>
          </div>
        </li>
    	</ul>
    </section>
  );
}

export default Project;
