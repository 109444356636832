import './Education.scss';

function Education() {
  return (
    <section className='education' id='education'>
      <div className='education__box'>
        <h2 className='education__title'>Education</h2>
        <ul className='education__list'>
          <li className='educard'>
            <img className='educard__img' src="https://drive.google.com/thumbnail?id=1z0WJU4a2SWWkPDMJaBCsd3Yxbq1inINV" alt="" />
            <h4 className='educard__title'>University of Waterloo</h4>
            <h5 className='educard__subtitle'>Bachelor's Degree in Geophysics</h5>
            <p className='educard__text'>September, 2009 - August, 2013</p>
          </li>
          <li className='educard'>
            <img className='educard__img' src="https://drive.google.com/thumbnail?id=1EAwEiqMn04HrzIn8dlYFt5aPNj95jKN9" alt="" />
            <h4 className='educard__title'>University of Houston</h4>
            <h5 className='educard__subtitle'>Master's Degree in Geophysics</h5>
            <p className='educard__text'>January, 2014 - May, 2016</p>
          </li>
          <li className='educard'>
            <img className='educard__img' src="https://drive.google.com/thumbnail?id=1y7QBK9LpH_2gIDhZsVnwlt2tD9UjO5Gd" alt="" />
            <h4 className='educard__title'>Georgia Institute of Technology</h4>
            <h5 className='educard__subtitle'>Master's Degree in Data Analytics</h5>
            <p className='educard__text'>May, 2018 - Dec, 2021</p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Education;
